import React from 'react'
import tw from 'twin.macro';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

import PostContainer from './post.style';
import ArticlePreviewContainer from './article-preview.style';

// const SectionContainer= tw.section`p-32 flex flex-wrap flex-row items-strech p-4`;
//.childMarkdownRemark.html
export default ({ article }) => (
    <ArticlePreviewContainer className="post">
        <Link
            to={`/blog/${article.slug}`}
            className="post-thumbnail"
            style={{
                      backgroundImage: `url(${article.heroImage.fluid.src})`,
                    }}
            />

        <div className="post-content">
            <h2 className="post-title">
                <Link to={`/blog/${article.slug}`}>{article.title}</Link>
            </h2>
            <span className="post-date">{article.publishDate}</span>
            <div className="post-description"
            dangerouslySetInnerHTML={{
                __html: article.description,
            }}
            />
            {article.tags &&
            article.tags.map(tag => (
                <p className="tag" key={tag}>
                {tag}
                </p>
            ))}            
        </div>
  </ArticlePreviewContainer>
);

/*
        {article.tags &&
        article.tags.map(tag => (
            <p className="tag" key={tag}>
            {tag}
            </p>
        ))}
*/
