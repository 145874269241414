import styled from 'styled-components';

const ArticlePreviewContainer = styled.article`
    width: 100%;
    max-width: 100%;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    min-height: 11rem;
    border-radius: 10px;
    overflow: hidden;
    transition: all .3s ease;
    box-shadow: 0 15px 45px -10px rgba(10, 16, 34, .2);

    &:hover {
      transform: translate(0px, -2px);
      box-shadow: -1px 1px 12px 0px rgba(31, 35, 46, .15);
      cursor: pointer;
    }
  
    .post-thumbnail {
      width: 30%;
      max-width: 100%;
      min-height: 11rem;
      background-size: cover;
      background-position: 50% 50%;
    }
  
    .post-content {
      padding: 1rem;
      width: 70%;
      .post-date,
      .post-words {
        font-size: 12px;
      }
      .post-title {
        margin: 0 0 10px;
        font-size: 30px;
        font-weight: 400;
        a {
          font-family: 'Barlow', serif;
          font-weight: 700;
          text-decoration: none;
          color: ${props => props.theme.colors.primaryDark};
          &:hover{
            color: ${props => props.theme.colors.secondaryLightColor};
          }

        }
      }
      p {
        margin-top: 0;
      }
    }
  }
  
  .tag {
    color: #A0A0A0;
    text-decoration: none;
    display: inline-block;
    padding: .33333rem .5rem;
    line-height: 1;
    border-radius: 2px;
    border: 1px solid #A0A0A0;
    margin-right: .5em;
}

  a.older-posts, a.newer-posts {
    font-size: 18px;
    display: inline-block;
    color: $body-color;
    transition: transform .2s;
  }
  
  a.older-posts:hover {
    transform: translateX(5px);
  }
  
  a.newer-posts:hover {
    transform: translateX(-5px);
  }


  @media only screen and (max-width: 992px){
    width: 48%;
    max-width: 100%;
    margin: 0 .9% 1.5%;
    flex-direction: column;

    .post-content, .post-thumbnail {
        width: 100%;
    }    
/*
    .post-content .post-title {
        margin: 0 0 5px;
    }    
    */
  }

  @media only screen and (max-width: 480px){
    width: 98%;
    max-width: 100%;
    margin: 2%;
    flex-direction: column;

    h2.post-title {
        font-size: 12pt !important;
    }

    .post-content, .post-thumbnail {
        width: 100%;
    }

    .post-thumbnail {
        min-height: 8rem;
    }
    /*
    .post-content .post-title {
        margin: 0 0 5px;
    }

*/
    .post-description {
        max-height: 100px;
        overflow: hidden;
        

        p {
            text-overflow: ellipsis;
        }
    }

    .tag {
        display: none;
    }
    
  }
  


  `
  export default ArticlePreviewContainer;  