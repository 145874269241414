import styled from 'styled-components';

const PagerNavContainer = styled.nav`
 display: flex;
 z-index: 1 !important;
 padding-bottom: 20px;

 .navigation-btn{
   padding: 0;
   color: ${props => props.theme.colors.primaryDark};
   min-width: max-content !important;
   text-transform: unset !important;

   &:hover{
    color: ${props => props.theme.colors.secondaryColor};
   }
 }
 ul.pagination-list {

    li {
      float: left;
      padding-left: 10px;
      padding-right: 10px;
      .pagination-link{

        font-size: 16pt;
        &:hover{
          color: ${props => props.theme.colors.secondaryColor};
        }

        &.is-current {
          color : ${props => props.theme.colors.grey};

          &:hover{
            color: ${props => props.theme.colors.secondaryColor};
          }          
        }
      }

      
    }
 }


 @media only screen and (max-width: 568px) {
  .navigation-btn {
    span {
      display: none;
    }

    svg {
      height: 2em !important;
    }
  }

  .pagination-link{
    font-size: 14pt;
  }

}
`

export default PagerNavContainer;