import React from 'react';
import tw from 'twin.macro';

import Layout, {Container, ContentWithPaddingXl} from '@components/Layout';
import Header from '@components/Header';
import SEO from '@components/Seo';
import CookieBanner from '@components/Cookies/CookieBanner';
import { Link } from 'gatsby';
import PostPreview from '@components/Post/PostPreview';
import ArticlePreview from '@components/Post/ArticlePreview';
import Pager from '@components/Pager';


const SectionContainer= tw.section`p-4 pt-4 md:pt-24 flex flex-wrap flex-row items-stretch`;
const ArticlesContainer= tw.div`sm:w-full max-w-6xl mx-auto pt-20 sm:pt-0 md:pt-10 z-0`;
const BlogIndex = ({location, pageContext}) => {

    const articles = pageContext.group;

    /*
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()
*/

    return (
        <Layout>
            <Header />
            <SEO
                lang="es" title="Cocina para ti" keywords={[`solidaridad`, `barrio`, `madrid`, `restaurantes`, `para llevar`, `banco de alimentos`]} 
            />            
            <SectionContainer id="blog">

            <ArticlesContainer>
                <Pager pageContext={pageContext} hasPages={false}/>
                {articles.map((article) => (
                    <ArticlePreview article={article} key={article.slug}/>
                ))}
                <Pager pageContext={pageContext} hasPages={true}/>
                </ArticlesContainer>
            </SectionContainer>    
            
            <CookieBanner/>
        </Layout>
    );
}

export default BlogIndex;