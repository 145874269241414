import React from 'react';
import { Link, navigate } from 'gatsby';
import PagerNavContainer from "./pager.style";
import Button from '@components/common/Button';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

// A sweet helper function to create pagination object
const createPaginationObjects = (length:number, page:number, increment = 2) =>
	Array.from({ length }, (_, i) => ({
		link: `/blog/page/${i + increment}/`,
		index: i + increment,
		current: page === i + increment,
    }));
     
const Pager = ({ pageContext, hasPages }) => {
    console.log('pager',pageContext);
    console.log('pages',hasPages);
    //const { previousPagePath, nextPagePath } = pageContext;
    const { group, index, pageCount, pages } = pageContext;
    const previousUrl = index -1 === 1 ? '/blog' : '/blog/page/' + (index - 1).toString()
    const nextUrl = '/blog/page/' + (index + 1).toString()
    const first = index===1;
    const last = (index === pageCount);


    // Create the navigation link
    let navItems = [
        {
            link: `/blog/`,
            index: 1,
            current: index === 1,
        },
    ];  

	if (pageCount <= 5) {
		navItems = [
			...navItems,
			...Array.from({ length: pageCount - 1 }, (_, i) => {
                //console.log('navItems --- i ',i);
                //console.log('navItems --- index ',index);
                return ({
				link: `/blog/page/${i + 2}/`,
				index: i + 2,
				current: index === i + 2,
			})}),
		];
	} else {
		// We have a situation where we have to show the first
		// item, three items around the current one
		// and also the last item
		/* eslint-disable no-lonely-if */
		if (index <= 3) {
			// If the current one is closer to the start
			navItems = [
				...navItems,
				...createPaginationObjects(3, index),
				{
					separator: true,
					index: 'starter-separator',
				},
				{
					link: `/blog/page/${pageCount}/`,
					index: pageCount,
					current: false,
				},
			];
		} else if (index > pageCount - 3) {
			// If the current one is closer to the last one
			navItems = [
				...navItems,
				{
					separator: true,
					index: 'finisher-separator',
				},
				...createPaginationObjects(4, index, pageCount - 3),
			];
		} else {
			navItems = [
				...navItems,
				{
					separator: true,
					index: 'starter-separator',
				},
				...createPaginationObjects(3, index, index - 1),
				{
					separator: true,
					index: 'finisher-separator',
				},
				{
					link: `/blog/page/${pageCount}/`,
					index: pageCount,
					current: false,
				},
			];
		}
		/* eslint-enable */
	}    
    return (
        <PagerNavContainer style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
            {!first && (
                <Button className="navigation-btn" onClick={() => navigate(previousUrl)}>
                    <FaChevronLeft className="icon-left" />
                    <span>más recientes</span>
                </Button>
            )}
        </div>
        {hasPages &&
        <ul className="pagination-list">
					{navItems.map(item => (
						<li key={item.index}>
							{item.separator ? (
								<span className="pagination-ellipsis">
									&hellip;
								</span>
							) : (
								<Link to={item.link} className={`pagination-link ${ item.current ? 'is-current' : '' }`} aria-label={`Goto page ${item.index}`} >
									{item.index}
								</Link>
							)}
						</li>
					))}
        </ul>   
        }
                
        <div style={{ justifySelf: 'flex-end' }}>
            {!last && (
                <Button className="navigation-btn" onClick={() => navigate(nextUrl)}>
                <span>publicaciones anteriores</span>
                <FaChevronRight className="icon-right" />
            </Button>                
            )}
        </div>     
        </PagerNavContainer>
    );
};

export default Pager;